import ACFImage from 'types/ACFImage';
import ACFSignup from 'types/ACFSignup';

import Image from 'components/content-blocks/partials/image';
import SchedulingSignup from 'components/modal/signups';

import './styles/features.scss';

type HeroFeaturesProps = {
  background_desktop_image: ACFImage;
  background_mobile_image: ACFImage;
  copy?: string;
  extra_content?: any
  image: {
    alt_text: string;
    source_url: string;
  };
  section?: string;
  signup: ACFSignup;
  sub_title: string;
  title: string;
  trialLength?: number;
};

export const HeroFeatures = ({
  background_desktop_image,
  background_mobile_image,
  image,
  section,
  signup,
  sub_title,
  title,
  copy,
  extra_content,
  trialLength,
}:HeroFeaturesProps) => {

  return (
    <section
      className="features-hero-section section hero"
      data-section={ section }
    >
      <div className="hero-main container">
        <div className="hero-left">
          <div>
            <h1 className="subheadline">{sub_title}</h1>
            <h2 className="headline hero-headline">{title}</h2>
            {copy && copy.trim() !== '' && <p className="hero-copy">{copy}</p>}
            {extra_content && <div className="extra-content" dangerouslySetInnerHTML={ { __html: extra_content } } />}
          </div>
          <div className="signup-cta-container">
            <SchedulingSignup
              uniqueId={ signup.uniqueId }
              modalTitle={ signup.modal_title }
              showInitFields={ true }
              legalText={ signup.legal_text }
              buttonInline
              fieldLayout="row"
              signupModalLaunch={ signup.launch_modal_button_text }
              signupModalSubmit={ signup.modal_submit_button_text }
              trialLength={ trialLength }
            />
          </div>
        </div>
        <div className="hero-image">
          <Image
            src={
              background_desktop_image
                ? background_desktop_image.source_url
                : image.source_url
            }
            srcSetPath={
              background_mobile_image && background_mobile_image.source_url
            }
            alt={
              background_desktop_image
                ? background_desktop_image.alt_text
                : image.alt_text
            }
            width="1600"
            height="400"
          />
        </div>
      </div>
    </section>
  ); 
};
